import Vue from 'vue'
import VueRouter from 'vue-router'
import Comex from '@/views/Comex.vue'
import ComexHome from '@/views/ComexHome.vue'
import Producto from '@/views/Producto.vue'
import Login from '@/views/Login.vue'
import Precio from '@/views/Precio.vue'
import Apps from '@/views/Apps.vue'
import Usuarios from '@/views/Usuarios.vue'
import Home from '@/views/Home.vue'
import BaseConocimiento from '@/views/BaseConocimiento.vue'
import MesaAyuda from '@/views/MesaAyuda.vue'
import Informes from '@/views/Informes.vue'
import StockDisponible from '@/components/informes/StockDisponible.vue'
import Iframe from '@/views/Iframe.vue'
import AnalisisCompra from '@/views/Iframes/AnalisisCompra.vue'
import ReporteRindegastos from '@/views/Iframes/ReporteRindegastos.vue'
import RegistroProveedores from '@/views/Iframes/RegistroProveedores.vue'
import RecepcionesDisponibles from '@/views/Iframes/RecepcionesDisponibles.vue'
import EstadisticaProducto from '@/views/Iframes/EstadisticaProducto.vue'
import IndicadoresKPI from '@/views/Iframes/IndicadoresKPI.vue'
import OtifEmpresarial from '@/views/Iframes/OtifEmpresarial.vue'
import OtifOperacional from '@/views/Iframes/OtifOperacional.vue'
import PorEntregar from '@/views/Iframes/PorEntregar.vue'
import PorEntregarGerencial from '@/views/Iframes/PorEntregarGerencial.vue'
import ReporteControlGuia from '@/views/Iframes/ReporteControlGuia.vue'
import NominasPago from '@/views/Iframes/NominasPago.vue'
import ControlSalidaGuias from '@/views/Iframes/ControlSalidaGuias.vue'
import StockDisponibleApp from '@/views/Iframes/StockDisponible.vue'
import LandingSeguimiento from '@/views/Iframes/LandingSeguimiento.vue'
import Bodegaje from '@/views/Iframes/Bodegaje.vue'

Vue.use(VueRouter)

const routes = [

  {
    path:'/',
    name:'Login',
    component:Login,
  },

  {
    path:'/baseConocimiento',
    name:'BaseConocimiento',
    component:BaseConocimiento
  },

  {
    path:'/home',
    name:'Home',
    component:Home
  },

  {
    path:'/comex',
    name:'comex',
    component: Comex
  },
  {
    path:'/ComexHome',
    name:'ComexHome',
    component: ComexHome
  },

  {
    path:'/producto',
    name:'producto',
    component: Producto
  },

  {
    path:'/apps',
    name:'apps',
    component: Apps
  },

  {
    path:'/usuarios',
    name:'usuarios',
    component:Usuarios
  },

  {
    path:'/precio',
    name:'precio',
    component: Precio
  },

  {
    path:'/ayuda',
    name:'ayuda',
    component: MesaAyuda
  },
  {
    path:'/informes',
    name:'informes',
    component: Informes
  },
  {
    path:'/mesaayuda',
    name:'MesaAyuda',
    component: MesaAyuda
  },
  
  {
    path:'/informes/stockDisponible',
    name:'StockDisponible',
    component: StockDisponible
  }, 
  {
    path: '/app',
    name: 'app',
    component : Iframe
  },
  {
    path: '/25',
    name: 'AnalisisCompra',
    component : AnalisisCompra
  },
  {
    path: '/17',
    name: 'RegistroProveedores',
    component : RegistroProveedores
  },
  {
    path: '/18',
    name: 'EstadisticaProducto',
    component : EstadisticaProducto
  },
  {
    path: '/19',
    name: 'IndicadoresKPI',
    component : IndicadoresKPI
  },
  {
    path: '/20',
    name: 'RecepcionesDisponibles',
    component : RecepcionesDisponibles
  },
  {
    path: '/21',
    name: 'ReporteControlGuia',
    component : ReporteControlGuia
  },
  {
    path: '/22',
    name: 'PorEntregar',
    component : PorEntregar
  },
  {
    path: '/23',
    name: 'LandingSeguimiento',
    component : LandingSeguimiento
  },
  /*{
    path: '/app-atika-por-entregar-kits',
    name: 'PorEntregarKits',
    component : PorEntregarKits
  },*/
  {
    path: '/24',
    name: 'ReporteRindegastos',
    component : ReporteRindegastos
  },
  {
    path: '/26',
    name: 'OtifEmpresarial',
    component : OtifEmpresarial
  },
  {
    path: '/27',
    name: 'OtifOperacional',
    component : OtifOperacional
  },
  {
    path: '/29',
    name: 'NominasPago',
    component : NominasPago
  },
  {
    path: '/30',
    name: 'ControlSalidaGuias',
    component : ControlSalidaGuias
  },
  {
    path: '/31',
    name: 'PorEntregarGerencial',
    component : PorEntregarGerencial
  },
  {
    path: '/32',
    name: 'StockDisponible',
    component : StockDisponibleApp
  },
  {
    path: '/33',
    name: 'Bodegaje',
    component : Bodegaje
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
