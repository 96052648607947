<template>
    <v-app>
        <v-card>
            <v-card-title>Permisos</v-card-title>
            <v-card-text>
                <v-expansion-panels>
                    <v-expansion-panel
                        v-for="grupo in dataGrupos" :key="grupo.id_grupo">
                        <v-expansion-panel-header v-on:click="mostrarAplicacionesGrupo(grupo)">
                            {{grupo.nombre}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col v-for="aplicacion in dataAplicaciones" :key="aplicacion.id_app">
                                    {{ aplicacion }}
                                    <v-checkbox
                                        v-on:click="actualizarGrupo(grupo)"
                                        v-model="chkApp"
                                        :value="aplicacion.id_app"
                                        :label="aplicacion.nombre">
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
        </v-card>
    </v-app>
</template>
<script>
import axios from 'axios'
    export default {
        component:'Permisos', 

        data() {
            return {
                dataGrupos:[],
                dataAplicaciones:[],
                chkApp:[]
            }
        }, 

        created() {
            this.listaGrupos()
        },

        methods: {
            listaGrupos:function() {
            axios.get(process.env.VUE_APP_API_GRUPOS_LISTAR).then(response => {
                        this.dataGrupos=response.data
                })
            },      
            
            mostrarAplicacionesGrupo: function(grupo) {
                let ruta=process.env.VUE_APP_API_APP_CONSULTARGRUPO + "/" + grupo.id_grupo
                console.log(ruta)
                axios.get(ruta).then(response => {
                    response.data.forEach(app => {
                        this.chkApp.push(app.id_grupo == null ? 0 : app.id_grupo.parseInt)
                    })
                    this.dataAplicaciones=response.data
                })
            },
            
            actualizarGrupo(grupo)
            {
                var ruta = process.env.VUE_APP_API_APP_LIMPIARGRUPO + "/" + grupo.id_grupo
                console.log("chkApp")
                console.log(this.chkApp)
                axios.get(ruta).then( response => {
                this.chkApp.forEach( idApp => {
                    if (idApp>0)
                    {
                    var ruta = process.env.VUE_APP_API_APP_ASOCIARGRUPO + "/" + idApp + "/" + grupo.id_grupo
                    console.log("Ruta actualizacion -> " + ruta)
                    axios.get(ruta)
                    }
                } )
            })
          
            this.snackTexto="Grupo " +  grupo.nombre + " actualizado"
            this.snackbar=true
            }

            
        }
    }
</script>