<template>
    <v-app fluid>
        <h2 class="text-center xanadu-texto">
            <br />
                                    Listas {{filtro}}
                                </h2>

                                <v-card v-show="cargador">
                                    <span>Momento por favor, cargando.. </span>
                                    <v-progress-linear
                                        indeterminate
                                        v-model="indicadorCarga" class="maximum_red-fondo" color="white">
                                    </v-progress-linear>
                                </v-card>
                                <v-expansion-panels popout>
                                    <v-expansion-panel
                                        v-for="lista in sabanaListas" v-bind:key="lista.id_producto_lista">
                                        <v-expansion-panel-header disable-icon-rotate>
                                            {{lista.nombre}}
                                            <template v-slot:actions>
                                                <v-tooltip top v-if="filtro=='pendientes'">
                                                    <template v-slot:activator="{on, attrs}">
                                                        <v-btn
                                                            fab
                                                            dark
                                                            small
                                                            class="botonPanel maximum_red-fondo"
                                                            v-on="on"
                                                            v-bind="attrs"
                                                            @click.native.stop="abrirPanelAprobar(lista.id_producto_lista)">
                                                            <v-icon class="white-texto" dark>
                                                                mdi-thumb-up
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Aprobar lista</span>
                                                </v-tooltip>

                                                <v-tooltip top v-if="filtro=='pendientes'">
                                                    <template v-slot:activator="{on,attrs}">
                                                        <v-btn
                                                            fab
                                                            dark
                                                            small
                                                            class="botonPanel xanadu_fondo"
                                                            v-on="on"
                                                            v-bind="attrs"
                                                            @click.native.stop="abrirPanelRechazar(lista.id_producto_lista)">
                                                            <v-icon class="white-texto" dark>
                                                                mdi-thumb-down
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Rechazar lista</span>
                                                </v-tooltip>

                                                <v-tooltip top>
                                                    <template v-slot:activator="{on, attrs}">
                                                        <v-btn
                                                            fab
                                                            small
                                                            dark
                                                            v-on="on"
                                                            v-bind="attrs"
                                                            class="botonPanel old_rose-fondo">
                                                            <v-icon class="white-texto" dark>
                                                                mdi-book-plus
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Expandir lista</span>
                                                </v-tooltip>



                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-data-table
                                                dense
                                                hide-default-footer
                                                :headers="tablaHeaders"
                                                disable-pagination
                                                no-data-text="No hay información en esta tabla"
                                                :items="lista.productos">

                                                <template v-slot:item.costoAdValoremTexto="{item}">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <span
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                >
                                                                {{item.costoAdValoremTexto}}
                                                            </span>
                                                        </template>
                                                       {{item.toolTipAdValorem}}
                                                    </v-tooltip>
                                                </template>

                                                <template v-slot:item.costoSeguroTexto="{item}">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <span
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                >
                                                                {{item.costoSeguroTexto}}
                                                            </span>
                                                        </template>
                                                      {{item.toolTipCostoSeguro}}
                                                    </v-tooltip>
                                                </template>

                                                <template v-slot:item.costoFleteImportacionUnitarioTexto="{item}">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <span
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                >
                                                                {{item.costoFleteImportacionUnitarioTexto}}
                                                            </span>
                                                        </template>
                                                       {{item.toolTipCostoFleteImportacionUnitario}}
                                                    </v-tooltip>
                                                </template>

                                                <template v-slot:item.costoFleteNacionalUnitarioTexto="{item}">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <span
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                >
                                                                {{item.costoFleteNacionalUnitarioTexto}}
                                                            </span>
                                                        </template>
                                                      {{item.toolTipCostoFleteNacionalUnitario}}
                                                    </v-tooltip>
                                                </template>

                                                <template v-slot:item.costoGastosAduanerosTexto="{item}">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <span
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                >
                                                                {{item.costoGastosAduanerosTexto}}
                                                            </span>
                                                        </template>
                                                       {{item.toolTipGastosAduaneros}}
                                                    </v-tooltip>
                                                </template>

                                                <template v-slot:item.totalCostosTexto="{item}">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <span
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                >
                                                                {{item.totalCostosTexto}}
                                                            </span>
                                                        </template>
                                                       {{item.toolTipTotalCostos}}
                                                    </v-tooltip>
                                                </template>

                                                <template v-slot:item.precioVETexto="{item}">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <span
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                >
                                                                <v-col class="xanadu-fondo white-texto">{{item.precioVETexto}}</v-col>
                                                            </span>
                                                        </template>
                                                       {{item.toolTipPrecioVE}}
                                                    </v-tooltip>
                                                
                                                </template>

                                                <template v-slot:item.precioVERetiraMOTexto="{item}">
                                                    <v-tooltip bottom>
                                                            <template v-slot:activator="{on, attrs}">
                                                                <span
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    >
                                                                    <v-col class="mandarin-fondo white-texto">{{item.precioVERetiraMOTexto}}</v-col>
                                                                </span>
                                                            </template>
                                                        {{item.toolTipPrecioVERetiraMO}}
                                                    </v-tooltip>
                                                </template>

                                                <template v-slot:item.precioSMLTexto="{item}">
                                                    <v-tooltip bottom>
                                                            <template v-slot:activator="{on, attrs}">
                                                                <span
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    >
                                                                    <v-col class="old_rose-fondo white-texto">{{item.precioSMLTexto}}</v-col>
                                                                </span>
                                                            </template>
                                                       {{item.toolTipPrecioSML}}
                                                    </v-tooltip>
                                                
                                                </template>

                                                <template v-slot:item.acciones="{item}" v-if="filtro=='pendientes'">
                                                    <v-icon
                                                        small
                                                        class="mr-2"
                                                        @click="abrirPanelPrecios(item)"
                                                        >

                                                            mdi-pencil
                                                        </v-icon>
                                                </template>
                                            </v-data-table>
                                        </v-expansion-panel-content>

                                    </v-expansion-panel>

                                </v-expansion-panels>

                                <v-dialog
                                width="500"
            v-model="panelAprobar">

            <v-card>
                <v-card-title>Aprobar los precios de la lista</v-card-title>
                <v-card-text>
                    <h3 class="text-justify pa-3">Importante : Se dispone a aprobar la lista de precios. <strong>Estos datos se actualizarán inmediatamente en SAP. Esta operación no se puede deshacer.</strong>. ¿Desea continuar?</h3>

                </v-card-text>
                <v-card-actions>
                    <v-btn
                        v-on:click="btnAprobarApruebo"
                        class="maximum_red-fondo white-texto">
                        Confirmar
                    </v-btn>
                    <v-btn
                        v-on:click="btnCancelarApruebo"
                        class="old_rose-fondo white-texto">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            width="350"
            v-model="panelRechazar">

            <v-card>
                <v-card-title>
                    Confirmar rechazo de lista
                </v-card-title>
                <v-card-text>
                    <v-select
                        v-model="idRechazoLista"
                        :items="dataMotivosRechazo"
                        item-text="estado"
                        item-value="id_estado"
                    >
                    </v-select>

                </v-card-text>
                <v-card-actions>
                    <v-btn
                        v-on:click="btnAprobarRechazo"
                        class="maximum_red-fondo white-texto">
                        Confirmar
                    </v-btn>
                    <v-btn
                        v-on:click="btnCancelarRechazo"
                        class="old_rose-fondo white-texto">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>



        </v-dialog>

        <!-- Dialogo para editar precios-->
        <v-dialog v-model="panelEdicionPrecios">
            <v-card>
                <v-card-title>Editar precios del producto</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-text-field outlined :name=preciosEditar.precioVENombreTexto v-model="preciosEditar.precioVETexto"  :label="preciosEditar.precioVENombre" :value="preciosEditar.precioVETexto" :placeholder="preciosEditar.precioVETexto"></v-text-field>

                        </v-col>
                        <v-col>
                            <v-text-field outlined :name="preciosEditar.precioVERetiraMOTexto" v-model="preciosEditar.precioVERetiraMOTexto" :label="preciosEditar.precioVERetiraMONombre" :value="preciosEditar.precioVERetiraMOTexto" :placeholder="preciosEditar.precioVERetiraMOTexto">

                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field outlined :name="preciosEditar.precioSMLTexto" v-model="preciosEditar.precioSMLTexto" :label="preciosEditar.precioSMLNombre" :value="preciosEditar.precioSMLTexto" :placeholder="preciosEditar.precioSMLTexto">

                            </v-text-field>
                        </v-col>

                    </v-row>

                </v-card-text>
                <v-card-actions>
                    <v-btn
                        v-on:click="btnAprobarPrecios"
                        class="maximum_red-fondo white-texto">
                        Confirmar
                    </v-btn>
                    <v-btn
                        v-on:click="btnCancelarPrecios"
                        class="old_rose-fondo white-texto">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog 
            transition="dialog-top-transition"
            width="500"
            v-model="actualizandoDatos">

            <v-card>
                <v-card-title class="maximum_red-fondo white-texto">
                    Actualizando datos
                </v-card-title>
                <v-card-content class="pa-10">
                    <h2 class="pa-10">Momento por favor... realizando la actualización en SAP. <br />
                    No cierre esta ventana.</h2>
                </v-card-content>
            </v-card>
            
        </v-dialog>
    </v-app>
</template>
<script>
import axios from 'axios'

export default ({
    name:'lista',

    data() {
        return {
            sabanaListas:'',
            valoresCabecera:'',
            indicadorCarga:0,
            panelRechazar:false,
            panelAprobar:false,
            dataMotivosRechazo:[],
            idRechazoLista:0,
            textoRechazo:'',
            panelEdicionPrecios:false,
            actualizandoDatos:false,
            preciosEditar:{},
            tablaHeaders: [
                {
                    text:'Itemcode',
                    align:'start',
                    value:'itemCode'
                },
                {
                    text:'Divisa',
                    align:'start',
                    value:'divisa'
                },
                {
                    text:'Costo unitario',
                    align:'start',
                    value:'montoTexto'
                },

                {
                    text:'% Ad Valorem',
                    align:'start',
                    value:'costoAdValoremTexto'
                },
                {
                    text:'Seguro',
                    align:'start',
                    value:'costoSeguroTexto'
                },
                {
                    text:'Flete Importación Unitario (M2)',
                    align:'start',
                    value:'costoFleteImportacionUnitarioTexto'
                },
                {
                    text:'Flete Nacional Unitario (M2)',
                    align:'start',
                    value:'costoFleteNacionalUnitarioTexto'
                },
                {
                    text:'% Gastos Aduaneros',
                    align:'start',
                    value:'costoGastosAduanerosTexto'
                },
                {
                    text:'Total Costos',
                    align:'start',
                    value:'totalCostosTexto'
                },
                {
                    text:'Precio VE Despacho MO',
                    align:'start',
                    value:'precioVETexto'
                },
                {
                    text:'Precio VE Retira MO',
                    align:'start',
                    value:'precioVERetiraMOTexto'
                },
                {
                    text:'Precio SML',
                    align:'start',
                    value:'precioSMLTexto'
                },
                {
                    text:'Acciones',
                    align:'start',
                    value:'acciones'
                }
            ]
        }

    },

    props: ['filtro'],
    created() {
        this.calculaPrecios()
    },

    methods: {
        calculaPrecios() {
            this.cargador=true
            this.sabanaListas=''
            axios.post(process.env.VUE_APP_RUTA_COMEX,{
                accion:'listasCalculadas',
                filtro:this.filtro
            }).then(response => {
                console.log("respuesta calculo precios")
                console.log(response.data)
                this.sabanaListas=response.data
                this.cargador=false
                
            })
        },

        abrirPanelRechazar(id_producto_lista=0) {
            this.id_producto_lista=id_producto_lista
            this.panelRechazar=true
            this.obtieneMotivosRechazo()
        },

        abrirPanelAprobar(id_producto_lista=0) {
            this.id_producto_lista=id_producto_lista
            this.panelAprobar=true
        },

        btnAprobarRechazo() {
            axios.post(
                process.env.VUE_APP_RUTA_COMEX,
                {
                    accion:'rechazaLista',
                    id_producto_lista:this.id_producto_lista,
                    idRechazoLista:this.idRechazoLista
                }).then(response => {
                 
                    this.calculaPrecios()
                    this.snackTexto="Lista de precio rechazada correctamente"
                    this.snackPrecio=true
                    this.textoRechazo=''
                    this.id_producto_lista=0
                })
            this.panelRechazar=false
        },

        btnCancelarRechazo() {
            this.panelRechazar=false
        }, 

        obtieneMotivosRechazo() {
            axios.post(process.env.VUE_APP_RUTA_COMEX,{
                accion:'obtieneMotivosRechazo'
            }).then(response => {
                this.dataMotivosRechazo=response.data
                
            })
        }, 

        btnAprobarApruebo() {
            this.actualizandoDatos=true
            console.log("id_producto_lista -> " + this.id_producto_lista)
            axios.post(
                process.env.VUE_APP_RUTA_COMEX,
                {
                    accion:'apruebaLista',
                    id_producto_lista:this.id_producto_lista,
                    texto:''
                }).then(response => {
                    this.calculaPrecios()
                    this.snackTexto="Lista de precio aprobada correctamente"
                    this.snackPrecio=true
                    this.panelAprobar=false;
                    this.actualizandoDatos=false;
                });
        },

        btnCancelarApruebo() {
            this.panelAprobar=false;
        },

        editarPrecioLista(item) {
            console.log("Editar precio")
            console.log(item)
        },

        abrirPanelPrecios(item) {
            this.preciosEditar=item
            this.panelEdicionPrecios=true
        },

        btnAprobarPrecios() {
            axios.post(
                process.env.VUE_APP_RUTA_COMEX,
                {
                    accion:'cambiarPrecios',
                    preciosEditar:this.preciosEditar,

                }).then(response => {
                    this.panelEdicionPrecios=false
                    this.snackTexto="Operación realizada."
                    this.snackPrecio=true
                });

        },

        btnCancelarPrecios() {
            this.panelEdicionPrecios=false
        }
    }
})
</script>
