<template>
    <v-app>
        <h1>Ver tickets</h1>
        <hr />
        <p>Proximamente..</p>
    </v-app>
</template>
<style>
</style>

<script>
export default  ({
    name:'Ver'
});

</script>