<template>

    <div>
        <v-data-table
            :headers="cabeceras"
            :items="dataTabla"
            no-data-text="No existen listas creadas"
            hide-default-footer
        >
            <template v-slot:item.estado="{item}">
                <v-chip :color=getColor(item.estado) class="white--text">{{item.estado}}</v-chip>

            </template>
        </v-data-table>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name:'TablaListas',

    data() {
        return {
            dataTabla:[],
            cabeceras:[
                {
                    text:'Nombre de la Lista',
                    align:'start',
                    value:'nombre',
                    sortable:true
                },
                {
                    text:'Fecha de carga',
                    align:'start',
                    value:'fecha',
                    sortable:true
                },
                {
                    text:'Estado de la lista',
                    align:'start',
                    value:'estado',
                    sortable:true
                }
            ]
        }
    },

    created() {
        this.cargaListas()
    },

    methods: {
        cargaListas() {
                axios.post(
                    process.env.VUE_APP_RUTA_COMEX,{
                    accion:'listarProductos'}).then(response => {
                        console.log("Respuesta")
                        console.log(response.data)
                        this.dataTabla=response.data
                      });
            },

        getColor(valor) {
            switch(valor) {
                case 'Aprobada' : return 'green'
                                  break;
                case 'Rechazada': return 'red'
                                  break;
                case 'Generada' : return 'blue'
                                  break;

            }
            console.log("valor -> " + valor)
        }
        }
    }
</script>