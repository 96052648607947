<template>
    <v-app>
        <v-card>
            <v-card-title>
                Confirmación de carga
            </v-card-title>
            <v-card-text>
                <p>Por favor, ingrese un nombre descriptivo para la carga realizada.</p>
                <v-text-field
                    label="Nombre de la carga"
                    hint="Ejemplo: Carga de productos 13 de Junio 2022"
                    v-model="nombreCarga">
                </v-text-field>

            </v-card-text>
            <v-card-actions>
                 <v-btn
                    class="mandarin-fondo white-texto"
                    v-on:click="clickAnterior"
                    >
                    Volver atrás
                </v-btn>
                <v-btn
                    class="space-cadet-fondo white-texto">
                    Confirmar Carga
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-app>
</template>

<script>
export default {
    name:'confirmacionCarga',

    data() {
        return {
            nombreCarga:''
        }
    },


    methods: {
        clickAnterior() {
            this.$emit('clickAnterior',1)
        },
    }
}
</script>