<template>
   <div>
        <v-text-field
            v-model="porcentaje"
            :rules="[rules.cantidad]"
            label="porcentaje"></v-text-field>
    </div>
</template>
<script>
export default
{
    name:'Porcentaje',

    data() {
        return {
            porcentaje:'',
            rules : {
                cantidad:value => value.match(/^((100(\.0{1,2})?)|(\d{1,2}(\.\d{1,2})?))$/) || 'Valor divisa no puede ser superior a 100%'
            }
        }
    },

    watch: {
        porcentaje() {
            this.enviaPorcentaje()
        }
    },


    methods: {
        enviaPorcentaje() {
            this.$emit("Porcentaje",this.porcentaje)
        }
    }
}

</script>