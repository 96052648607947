<template>
    <div>
        <v-select
            v-model="origenMarca"
            :items="dataMarca"
            item-text="Descr"
            item-value="FldValue"
            v-on:change="mandaMarca"
            label="Origen marca">
        </v-select>

    </div>
</template>
<script>
import axios from 'axios'
export default
{
    name:'origenMarca',

    data() {
        return {
            origenMarca:{
                FldValue:'',
                Descr:''
            },
            dataMarca:[
                {
                    FldValue:'',
                    Descr:''
                }
            ]
        }
    },

    created(){
        this.consultaOrigenMarca()
    },

    methods: {
        mandaMarca() {
            this.$emit('OrigenMarca',this.origenMarca)
        },

        async consultaOrigenMarca() {
            await axios.post(
                process.env.VUE_APP_RUTA_CONSULTAS_SAP,
                {
                    method:"POST",
                    accion:"consultaOrigenMarca"
                }).then(response => {
             
                    this.dataMarca=response.data
                })
        }
    }
}

</script>