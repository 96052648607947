<template>
    <div class="appLogin white-fondo">
        <div class="edificio">
        <v-container class="alMedio login">
            <v-card elevation="24">
                <v-card-title>
                    <span class="texto_centrado">
                        Ingreso de usuarios
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-form
                        @submit.prevent="submit">
                    <v-text-field
                        v-model="objLogin.usuario"
                        label="nombre de usuario Atika"
                        hint="Coloque su nombre de correo atika antes de la @"
                        required
                        :rules="reglas"
                        >
                    </v-text-field>
                    <v-text-field
                        v-model="objLogin.pass"
                        label="Contraseña"
                        hint="Use la misma contraseña que usa para ingresar a su equipo, o al correo de Atika."
                        required
                        @keydown.enter="validaUsuario"
                        type="password">
                    </v-text-field>
                    </v-form>


                </v-card-text>
                <v-card-actions class="mt-auto">
                    <v-btn
                        class="maximum_red-fondo white-texto"
                        :disabled="!validarForm"
                        v-on:click="validaUsuario"
                        block
                        >
                        Ingresar a plataforma
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-snackbar v-model="snackbar" color="red darken-4">
            {{snackText}}
        </v-snackbar>
        </div>
        
    </div>
</template>
<style>
    .login {
        width:400px;
    }

    .alMedio {
	margin-top:12%;
	margin-left:35%;
    }

    .edificio {
        background-image:url('@/assets/edificio.jpg');
        background-position: center;
        background-size: cover;
        width:100%;
        height:100% !important;
        min-height:640px;
        position:absolute;
        top:0%;
        z-index:-1;
    }
</style>
<script>
import axios from 'axios' 
import HeaderLogin from '@/components/globales/headers/HeaderLogin.vue'
import Indicadores from '@/components/dashboard/Indicadores.vue'


export default {
    name: "Login",
    data() {
        return {
            objLogin: {
                usuario: "",
                pass: ""
            },
            snackbar: false,
            snackText: "",
            reglas: [val => (val || "").length > 0 || "El campo es requerido"]
        };
    },
    computed: {
        validarForm() {
            return (this.objLogin.usuario && this.objLogin.pass);
        }
    },
    methods: {
        validaUsuario() {
            this.snackbar = true;
            this.snackText = "Validando usuario";
            //let ruta=process.env.VUE_APP_RUTA_LOGIN + this.objLogin.usuario + "/" + this.objLogin.password
            let ruta=process.env.VUE_APP_RUTA_LOGIN 
            axios.post(ruta, this.objLogin).then(response => {
                if (response.data.activo==1) {
                    this.$cookies.set('idUsuario',response.data.id_usuario)
                    this.$cookies.set('id_usuario', JSON.stringify(response.data.id_usuario))
                    this.$cookies.set('nombreUsuario',response.data.nombre)
                    this.$cookies.set('login',this.objLogin.usuario)
                    this.$router.push({
                        name:"Home",
                        params:{usuarioLogin:response.data.nombre}
                    });
                }
                else {
                    this.snackbar = true;
                    this.snackText = "Error : Usuario y/o contraseña incorrecta.";
                }
            });
        }
    },
    components: {  Indicadores}
}

</script>