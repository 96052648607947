<template>
    <div>
        <v-select
            v-model="UnidadNegocios"
            :items="dataUnidadNegocios"
            item-text="Descr"
            item-value="FldValue"
            v-on:change="mandaUnidadNegocios"
            label="Unidad de Negocios">
        </v-select>

    </div>
</template>
<script>
import axios from 'axios'
export default
{
    name:'UnidadNegocios',

    data() {
        return {
            UnidadNegocios:'',
            dataUnidadNegocios:[
                {
                    FldValue:'',
                    Descr:''
                }
            ]
        }
    },

    created(){
        this.consultaUnidadNegocios()
    },

    methods: {
        mandaUnidadNegocios() {
            this.$emit('UnidadNegocios',this.UnidadNegocios)
        },

        async consultaUnidadNegocios() {
            await axios.post(
                process.env.VUE_APP_RUTA_CONSULTAS_SAP,
                {
                    method:"POST",
                    accion:"consultaUN"
                }).then(response => {
                    this.dataUnidadNegocios=response.data
                })
        }
    }
}

</script>