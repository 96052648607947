<template>
    <div>
        <v-select
            v-model="divisa"
            :items="items"
            type="number"
            v-on:change="mandaDivisa"
            label="Divisa">
        </v-select>

    </div>
</template>
<script>
export default
{
    name:'Divisa',

    data() {
        return {
            divisa:'',
            items:["Euro","Dolar"],
        }
    },

    methods: {
        mandaDivisa() {
            this.$emit('Divisa',this.divisa)
        }
    }
}

</script>