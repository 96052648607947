<template>
    <v-app>
        <v-card height="50vh">
            <v-card-title class="xanadu-fondo white-texto">
                <span class="texto_centrado">Flete de importación</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <Fecha campo="flete_importacion" @registraFecha="registraFecha"></Fecha>
                    </v-col>
                    <v-col>
                        <Divisa @Divisa="capturaDivisa"></Divisa>
                    </v-col>
                    <v-col>
                        <origenMarca @OrigenMarca="registraOrigenMarca"></origenMarca>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <TipoTransporteProveedor @TipoTransporteProveedor="registraTipoTransporteProveedor"></TipoTransporteProveedor>


                    </v-col>
                    <v-col>
                        <Monto @monto="registraMonto"></Monto>
                    </v-col>
                </v-row>
                <span class="maximum_red-texto" v-show="validaFecha">La fecha no puede ir vacía</span>

            </v-card-text>
            <v-card-actions>
                <v-btn
                    @click="btnAceptar"
                    class="maximum_red-fondo white-texto">
                    Aceptar
                </v-btn>
                <v-btn
                    @click="btnCancelar"
                    class="old_rose-fondo space-cadet-texto">
                    Cancelar
                </v-btn>

            </v-card-actions>
        </v-card>

        <TablaConsulta
            :recargaTabla="recargaTabla"
            cabecera="Fecha,Divisa,Origen de la marca,Tipo Transporte Proveedor, monto"
            criterio="flete_importacion_fecha,flete_importacion_divisa,flete_importacion_origen_marca,flete_importacion_tipo_transporte_proveedor,flete_importacion_monto">
        </TablaConsulta>
    </v-app>
</template>


<script>
import Fecha from '@/components/comex/Elementos/Fecha.vue'
import Divisa from '@/components/comex/Elementos/Divisa.vue'
import Monto from '@/components/comex/Elementos/Monto.vue'
import OrigenMarca from '@/components/comex/Elementos/OrigenMarca.vue'
import TipoTransporteProveedor from './Elementos/TipoTransporteProveedor.vue'
import TablaConsulta from '@/components/comex/Elementos/TablaConsulta.vue'


export default {
    name:'fleteImportacion',

    components:{ Fecha, Divisa, Monto, OrigenMarca, TipoTransporteProveedor, TablaConsulta },

    props:['modo'],

    data() {
        return {
            validaFecha:false,
            recargaTabla:0,
            ObjFleteImportacion: {
                fecha:'',
                origen_marca:'',
                divisa:'',
                tipo_transporte_proveedor:'',
                monto:''
            }

        }
    },

    methods: {
        btnAceptar: function() {
            if (this.ObjFleteImportacion.fecha.length==0) {
                this.validaFecha=true
            } else {
                this.recargaTabla++
                this.$emit('objFleteImportacion',this.ObjFleteImportacion)
            }
        },

        btnCancelar: function() {
            this.validaFecha=false
            this.$emit('objFleteImportacion',null)
        },

         registraFecha(valor)
        {
            this.ObjFleteImportacion.fecha=valor
        },

        registraOrigenMarca(valor)
        {
            this.ObjFleteImportacion.origen_marca=valor
        },

        registraTipoTransporteProveedor(valor)
        {

            this.ObjFleteImportacion.tipo_transporte_proveedor=valor

        },

        capturaDivisa(valor)
        {
            this.ObjFleteImportacion.divisa=valor
        },

         registraMonto(valor)
        {
            this.ObjFleteImportacion.monto=valor
        }
    }
}
</script>