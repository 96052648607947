<template>
    <div class="white-fondo">

        <v-btn class="maximum_red-fondo white-texto mt-5" v-on:click="abrirFormulario">
            Agregar nuevo grupo
        </v-btn>

       
        <v-card>
            <v-card-title>
                Grupos
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="cabeceraGrupos"
                    :items="dataGrupos"
                    hide-default-footer
                >

               

                <template v-slot:item.acciones="{item}">
                    <v-btn class="maximum_red-fondo white-texto mr-5">
                        Editar
                    </v-btn>
                    
                    <v-btn color="#A3A3A3" v-on:click="borrarGrupo(item)">
                        Eliminar
                    </v-btn>
                </template>

                </v-data-table>
            </v-card-text>
           
        </v-card>

       
      
        <v-dialog v-model="formGrupo" width="400">
            <v-card>
                <v-card-title>
                    Registrar nuevo grupo
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <v-text-field
                            v-model="objGrupo.nombre"
                            label="Nombre del grupo"
                            required>
                        </v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="maximum_red-fondo white-texto" v-on:click="ejecutaFormulario">
                        Registrar grupo
                    </v-btn>
                    <v-btn class="old_rose-fondo white-texto" v-on:click="formGrupo=false">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
   
    </div>
</template>


<script>
    import axios from 'axios'

    export default({
        component:'Grupos', 

        data() {
        return {
            objGrupo: {
                id_grupo: "",
                nombre: "",
            },

            cabeceraGrupos: [
                {
                    text:'Nombre grupo',
                    value:'nombre',
                },
                {
                    text:'Acciones',
                    value:'acciones'
                }
            ],
            dataGrupos:[],
            snackbarGrupo: false,
            snackAppTexto: "",
            formGrupo:false,
            textoTituloFormulario:''       
        }
        },
        
        created() {
            this.mostrarGrupos()
        },

        methods: {
            mostrarGrupos: function() {
                axios.get(process.env.VUE_APP_API_GRUPOS_LISTAR).then(response => {
                    console.log(response.data)
                    this.dataGrupos=response.data
                })
            }, 

            abrirFormulario:function() {
                this.textoTituloFormulario="Registrar nuevo grupo"
                this.formGrupo=true
            },

            ejecutaFormulario:function() {
               let ruta = process.env.VUE_APP_API_GRUPOS_CREAR  + "/" + 
               this.objGrupo.nombre

                axios.get(ruta).then(response => {
                    console.log("respuesta axios")
                    console.log(response.data)
                    this.mostrarGrupos()
                    this.formGrupo=false
                })
            }, 

            borrarGrupo:function(item) {
                console.log("item ->")
                console.log(item)
              //  VUE_APP_API_GRUPOS_ELIMINAR=$VUE_APP_BASE/grupos/eliminar/$VUE_APP_API_KEY
                let ruta = process.env.VUE_APP_API_GRUPOS_ELIMINAR  + "/" + 
                item.id_grupo
               axios.get(ruta).then(response => {
                    console.log("Respuesta axios")
                    console.log(response.data)
                    if (response.data.respuesta=="no eliminada") 
                    {
                        console.log("Error. No se puede borrar una aplicación que tiene usuarios asociados.")
                    } else 
                    {
                        console.log("Aplicación eliminada.")
                    }
                    this.mostrarGrupos()
               })
            }
        }
                
    })
</script>