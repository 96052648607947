<template>
    <v-app class="w-100 h-100">
        <v-row>
            <v-col class="col-lg-2">
                    <Menu></Menu>    
            </v-col>
            <v-col class="col-lg-10">

                <v-container fluid class="white-fondo">
                <v-tabs
                    v-model="tab"
                    >
                    <v-tabs-slider class="old_rose-fondo"></v-tabs-slider>
                    <v-tab key="autoayuda" href="#autoayuda" class="jet-texto">
                        Opciones de autoatención
                    </v-tab>
                    <v-tab key="subir" href="#subir" class="jet-texto">
                       Subir ticket
                    </v-tab>

                    <v-tab key="ver" href="#ver" class="jet-texto">
                        Ver tickets creados
                    </v-tab>             
                </v-tabs>

               
                <template>
                    <v-tabs-items v-model="tab">
                        <v-tab-item id="autoayuda" value="autoayuda">
                            <Autoayuda></Autoayuda>
                        </v-tab-item>
                        <v-tab-item id="subir" value="apps">
                            <Subir></Subir>
                        </v-tab-item>
                        <v-tab-item id="ver" value="permisos">
                            <Ver></Ver>
                        </v-tab-item>
                </v-tabs-items>
                </template>

              
                
                </v-container>   
            </v-col>
        </v-row>

        
       
    </v-app>
</template>

<script>
import Menu from '@/components/Menu.vue'
import Subir from '@/components/mesaAyuda/Subir.vue'
import Ver from '@/components/mesaAyuda/Ver.vue'
import Autoayuda from '@/components/mesaAyuda/Autoayuda.vue'

export default {

    name: 'MesaAyuda',

    components: { Menu, Subir, Ver, Autoayuda },

    data() {
        return {
            tab: "",
            dataApps: []
        }
    }
}
    
    </script>
