<template>
    <v-app>
        <br/>
        <br/>
        <v-layout row wrap>
            <v-flex xs12 sm12 md3 xxl3>  <!--xs12 sm6-->
                <Fecha></Fecha>
            </v-flex>
            <v-flex xs12 sm12 md8 xxl8> <!--xs12 sm6-->
                <Indicadores></Indicadores>
            </v-flex>
        </v-layout>
    </v-app>
</template>
<script>
import Fecha from '@/components/dashboard/Fecha.vue'
import Indicadores from '@/components/dashboard/Indicadores.vue'

export default {
    name:'Dashboard',
    components:{Fecha,Indicadores},
    data() 
    {
        return {
            iframe:null
        }
    }, 
    methods: {
        onLoad(frame) {
            this.iframe=frame.contentWindow
        }
    }   
}
</script>