<template>
    <div>
        <v-row>
            <v-col class="col-lg-2">
                <Menu></Menu>
            </v-col>
            <v-col class="col-lg-10">
                <formularioComex></formularioComex>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Menu from '@/components/Menu.vue'
import formularioComex from '@/components/formularioComex.vue'

export default {
    components: {formularioComex,Header,Menu}
}


</script>