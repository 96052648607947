<template>
    <div>
        <v-text-field
            v-model="monto"
            type="number"
            label="monto">
        </v-text-field>
    </div>
</template>
<script>
export default
{
    name:'Monto',

    data() {
        return {
            monto:''
        }
    },

    watch: {
        monto() {
            this.enviaMonto()
        }
    },

    methods: {
        enviaMonto() {
            this.$emit('monto',this.monto)
        }
    }

}

</script>