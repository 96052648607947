<template>
    <div class="app-header">
        <v-app-bar
                class="white-fondo cabecera"
                dense
                >
            <template v-slot:img="{ props }">
            <v-img
            v-bind="props"
            class="imagen_fondo"
            src="@/assets/logo.svg"

            ></v-img>
        </template>
            <v-app-bar-title class="jet-texto texto_derecha">
                        Formulario de registro de costos de internación
            </v-app-bar-title>
        </v-app-bar>
    </div>
</template>
<style>
.cabecera {
    height:50px !important;
    margin-top:15px !important;
}

.imagen_fondo {
    height:30px;
    width:120px;
    margin-left:15px;
    margin-bottom:15px;
}

.texto_derecha {
    margin-left:35%;
}
</style>
<script>
export default {
    name:'Header'
}
</script>