<template>
    <v-app>
        <v-row>
            <v-col class="col-lg-2">
                <Menu></Menu>
            </v-col>
            <v-col class="col-lg-10">
                <v-card>
        <v-card-title>
            Administrador de usuarios
        </v-card-title>
        <v-card-text>

            <v-container fluid class="white-fondo">
                <v-tabs
                    v-model="tab"
                    >
                    <v-tabs-slider class="maximum_red-fondo"></v-tabs-slider>
                    <v-tab key="usuarios" href="#usuarios" class="jet-texto">
                        Administración de usuarios
                    </v-tab>

                    <v-tab key="grupos" href="#grupos" class="jet-texto">
                        Administración de grupos
                    </v-tab>

                    <v-tab key="asociar" href="#asociar" class="jet-texto">
                        Asociar usuarios a grupos
                    </v-tab>
                </v-tabs>

               
                <template>
                    <v-tabs-items v-model="tab">

                        <v-tab-item id="usuarios" value="usuarios">
                            <UsuariosInterno></UsuariosInterno>
                        </v-tab-item>

                        <v-tab-item id="grupos" value="grupos">
                            <Grupos></Grupos>
                        </v-tab-item>

                        <v-tab-item id="asociar" value="asociar">
                           <AsociarGrupos></AsociarGrupos>
                        </v-tab-item>
                </v-tabs-items>
                </template>
        
            </v-container>

        </v-card-text>

    </v-card>  
            </v-col>
        </v-row>
          
    </v-app>
</template>

<script>
import UsuariosInterno from '@/components/usuarios/UsuariosInterno.vue'
import Grupos from '@/components/usuarios/Grupos.vue'
import AsociarGrupos from '@/components/usuarios/AsociarGrupos.vue'
import Menu from '@/components/Menu.vue'

    export default {
    name: 'Usuarios',

    components: {UsuariosInterno, Grupos, AsociarGrupos, Menu },

    data() {
        return {
            tab: "",
            dataApps: []
        };
    },
}
    
    </script>
