<template>
    <v-app>
        <v-row>
            <v-col v-for="indice in iterador" :key="indice">
                <div @click="abrirInfo">
                    <v-img
                        src="@/assets/calendario.png">
                    </v-img>
                </div>
            </v-col>
        </v-row>

        <v-dialog v-model="muestraInfo">
            <v-card>
                <v-card-title>
                    Datos ingresados el 29 de Julio 2022
                </v-card-title>
                <v-card-text>
                    <h2>Ad Valorem</h2>
                    <v-list>
                        <v-list-item>
                            Origen Marca : Italia
                        </v-list-item>
                        <v-list-item>
                            Porcentaje : 12%
                        </v-list-item>
                    </v-list>
                    <hr />
                    <h2>Flete Nacional</h2>
                    <v-list>
                        <v-list-item>
                            Tipo Transporte Proveedor : Marítimo LCL
                        </v-list-item>
                        <v-list-item>
                            Monto : $125.000
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-btn v-on:click="cerrarVentana">
                        Cerrar ventana
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-app>
</template>

<style>

</style>

<script>
export default {
    name:'Calendario',
    data() {
        return {
            iterador:[0,1,2,3,4,5,6,7],
            muestraInfo:false,
        }
    },

    methods: {
        cerrarVentana() {
            this.muestraInfo=false
        },

        abrirInfo() {
            this.muestraInfo=true
        }
    }

}
</script>