<template>
    <div class="white-fondo">

        <v-btn class="maximum_red-fondo white-texto mt-5" v-on:click="agregarUsuario">
            <v-icon class="mr-5">
                mdi-account-plus
            </v-icon>
            Agregar nuevo usuario
        </v-btn>

       
        <v-card>
            <v-card-title>
                Usuarios
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="cabeceraUsuarios"
                    :items="dataUsuarios"
                    height="700"
                    :fixed-header=true
                    :footer-props="{'items-per-page-options':[50]}"
                >

                <template v-slot:item.sexo="{item}">
                    <v-icon v-if="item.sexo=='m'" class="maximum_red-texto">
                        mdi-face-man
                    </v-icon>
                    <v-icon v-else-if="item.sexo=='f'" class="maximum_red-texto">
                        mdi-face-woman
                    </v-icon> 

                    
                </template>
               

                <template v-slot:item.acciones="{item}">
                    <v-btn 
                        v-on:click="editarUsuario(item)"
                        size="x-small"
                        class="maximum_red-fondo white-texto" >
                        <v-icon>
                            mdi-account-edit
                        </v-icon>
                    </v-btn>
                    
                    <v-btn 
                        class="ml-2" color="#A3A3A3" v-on:click="borrarUsuario(item)">
                        <v-icon>
                            mdi-account-remove
                        </v-icon>
                    </v-btn>

                    <v-btn class="jet_fondo ml-2">
                        <v-icon>
                            mdi-account-cancel
                        </v-icon>
                    </v-btn>
                </template>

                </v-data-table>
            </v-card-text>
           
        </v-card>

       
      
        <v-dialog v-model="formUsuario" width="400">
            <v-card>
                <v-card-title>
                    {{ textoTituloFormulario }}
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <v-text-field
                            v-model="objUsuario.nombre"
                            label="Nombre del Usuario"
                            value="objUsuario.nombre"
                            required>
                        </v-text-field>
                        <v-text-field
                            v-model="objUsuario.usuario"
                            label="Usuario Active Directory"
                            value="objUsuario.correo"
                            required>
                        </v-text-field>
                        <v-text-field
                            v-model="objUsuario.sap"
                            label="Nombre usuario SAP"
                            value="objUsuario.sap"
                            required>
                        </v-text-field>
                        <v-text-field
                            v-model="objUsuario.anydesk"
                            label="Código Anydesk"
                            value="objUsuario.anydesk"
                            required>
                        </v-text-field>
                        <v-radio-group v-model="objUsuario.sexo">
                            <v-radio 
                                label="Masculino"
                                value="m">
                            </v-radio>
                            <v-radio
                                label="Femenino"
                                value="f">
                            </v-radio>
                        </v-radio-group>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="maximum_red-fondo white-texto" v-on:click="ejecutaFormulario">
                        {{ textobtnFormulario }}
                    </v-btn>
                    <v-btn class="old_rose-fondo white-texto" v-on:click="formUsuario=false">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbarApp">
            {{ snackAppTexto }}
        </v-snackbar>
    </div>
</template>


<script>
    import axios from 'axios'

    export default({
        component:'UsuariosInterno', 

        data() {
            return {
                objUsuario: {
                    id_usuario: 0,
                    nombre: "",
                    usuario:"",
                    sexo:"",
                    sap:"",
                    anydesk:""
                },
                cabeceraUsuarios: [
                    {
                        text:'Nombre usuario',
                        value:'nombre',
                    },
                    {
                        text:'Usuario AD',
                        value:'correo',
                    },
                    {
                        text:'Sexo',
                        value:'sexo'
                    }, 
                    {
                        text:'Usuario SAP',
                        value:'sap'
                    },
                    {
                        text:'Anydesk',
                        value:'anydesk'
                    },
                    {
                        text:'Acciones',
                        value:'acciones'
                    }
                ],
                dataUsuarios:[],
                snackbarApp: false,
                snackAppTexto: "",
                formUsuario:false,
                textoTituloFormulario:'Ingresar nuevo usuario',
                textobtnFormulario:'Registrar usuario',
                botonDesactivar:'Desactivar'       
            }
        },
        
        created() {
            this.mostrarUsuarios()
        },

        methods: {
            mostrarUsuarios: function() {
                console.log("mostrar usuarios")
                axios.get(process.env.VUE_APP_API_USUARIOS_LISTAR).then(response => {
                    this.dataUsuarios=response.data
                })
            }, 

            agregarUsuario:function() {
                this.textoTituloFormulario="Agregar nuevo usuario"
                this.textobtnFormulario="Registrar usuario"
                this.formUsuario=true
            },

            ejecutaFormulario:function() {
                console.log("Ejecuta formulario")
                console.log(this.objUsuario)
                var prefijo=''
                switch(this.textobtnFormulario)
                    {
                        case 'Registrar usuario': prefijo=process.env.VUE_APP_API_USUARIOS_CREAR
                                                this.snackAppTexto="Usuario agregado exitosamente"
                                                  break;
                        case 'Actualizar usuario': prefijo=process.env.VUE_APP_API_USUARIOS_ACTUALIZAR
                                                this.snackAppTexto="Usuario actualizado exitosamente"
                    }
                console.log(this.textobtnFormulario)
                let activo=1
                let ruta = prefijo + "/" + 
                this.objUsuario.usuario + "/" + 
                this.objUsuario.nombre + "/" + 
                this.objUsuario.sexo + "/" +
                this.objUsuario.sap + "/" + 
                this.objUsuario.anydesk + "/" + 
                activo //Cambiar  
                + "/" + this.objUsuario.id_usuario

                console.log("Ruta invocada -> " + ruta)
                axios.get(ruta).then(response => {
                    console.log("respuesta axios")
                    console.log(response.data)
                    this.mostrarUsuarios()
                    this.formUsuario=false
                    this.snackbarApp=true
                    
                })
            }, 

            borrarUsuario:function(usuario) {
               let ruta =process.env.VUE_APP_API_USUARIOS_ELIMINAR + "/" + 
               usuario.id_usuario

               axios.get(ruta).then(response => {
                    console.log("Respuesta axios")
                    console.log(response.data)
                    this.mostrarUsuarios()
               })
            }, 

            editarUsuario:function(usuario) {
                let ruta = process.env.VUE_APP_API_USUARIOS_CONSULTAR + "/" + 
                    usuario.id_usuario
                axios.get(ruta).then(response => {
                    this.objUsuario.nombre=response.data[0].nombre
                    this.objUsuario.usuario=response.data[0].correo
                    this.objUsuario.sap=response.data[0].sap
                    this.objUsuario.sexo=response.data[0].sexo
                    this.objUsuario.anydesk=response.data[0].anydesk
                    this.objUsuario.id_usuario=usuario.id_usuario
                })

                console.log("editar usuario")
                this.textoTituloFormulario="Editar usuario"
                this.textobtnFormulario="Actualizar usuario"
                this.formUsuario=true
            }
        }
                
    })
</script>