<template>
    <v-app>
        <v-card>
            <v-card-title>
                Revisión de la importación
            </v-card-title>
            <v-card-text>
                <v-system-bar dark class="barra xanadu-fondo white-texto">
                    Total registros subidos : {{resultadoExcel.datos_exitosos}}
                </v-system-bar>
                <v-system-bar class="barra jet-fondo white-texto">
                    Total registros omitidos : {{resultadoExcel.datos_defectuosos}}
                </v-system-bar>

                <v-card class="maximum_red-texto ma-5" v-if="resultadoExcel.datos_defectuosos>0">
                    <v-card-title>Aviso: Los siguientes productos no fueron encontrados :</v-card-title>

                    <v-card-text>
                        <v-row no-gutters>
                            <v-col cols="3" v-for="item in resultadoExcel.no_encontrados" v-bind:key="item.itemCode">
                                <v-list-item>
                                    <v-icon class="jet-texto">mdi-alpha-x-circle</v-icon>
                                    <v-list-item-title>{{item.itemCode}}</v-list-item-title>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    
                </v-card>
            </v-card-text>
            <v-card-actions>
                    <v-btn class="old_rose-fondo jet-texto" v-on:click="clickAnterior">
                        Volver atrás
                    </v-btn>
                    <v-btn class="maximum_red-fondo white-texto" v-on:click="clickSiguiente" v-if="resultadoExcel.datos_exitosos">
                        Confirmar carga de Excel
                    </v-btn>
            </v-card-actions>
        </v-card>

        <h2>Detalle </h2>

        <v-data-table
            :headers="cabeceras"
            :items="resultadoExcel.excel"
            :search="buscar"
            hide-default-footer
            disable-pagination
            >
            <template v-slot:top>
                <v-text-field
                    v-model="buscar"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                    >
                </v-text-field>
            </template>
        </v-data-table>

        <v-dialog v-model="dialogConfirmar">
            <v-card>
                <v-card-title>
                    ¿Confirmar creación de lista?
                </v-card-title>
                <v-card-actions>
                    <v-btn v-on:click="cargaExcelenBD" class="maximum_red-fondo white-texto">
                        Confirmar
                    </v-btn>

                    <v-btn v-on:click="dialogConfirmar=false" class="old_rose-fondo white-texto">
                        Cancelar
                    </v-btn>
                    <v-container v-show="mostrarCargador">
                        <v-row>
                            <v-col :lg="9">
                                <h5>Momento por favor, guardando productos.. </h5>
                            </v-col>
                            <v-col :lg="3">
                                <v-img src="@/assets/loading.gif" style="width:60px;height:60px"></v-img>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbarRevision"
            persistent
            >
            Se ha guardado exitosamente la lista con el nombre {{textoLista}}
        </v-snackbar>

    </v-app>
</template>

<style>
.barra{
    width:390px;
    margin-left:-9px;
}
</style>


<script>
import axios from 'axios'
export default {
    name:'revisionArchivo',

    props : {
        resultadoExcel:Object
    },

    created() {
        console.log("resultadoExcel")
        console.log(this.resultadoExcel)
    },

    data() {
        return {
            buscar:'',
            dialogConfirmar:false,
            snackbarRevision:false,
            mostrarCargador:false,
            textoLista:'',
            cabeceras:[
                {
                    text:'itemCode',
                    align:'start',
                    value:'itemCode',
                    sortable:true
                },
                {
                    text:'Nombre',
                    align:'start',
                    value:'itemName',
                    sortable:true
                },
                {
                    text:'Marca',
                    align:'start',
                    value:'marca',
                    sortable:true
                },
                {
                    text:'Divisa',
                    value:'divisa',
                    sortable:true
                },
                {
                    text:'Monto',
                    value:'monto',
                    sortable:true
                }
            ]
        }
    },

    methods : {
        clickAnterior() {
            this.$emit('clickAnterior',1)
        },

        clickSiguiente() {
            this.dialogConfirmar=true
        },

        cargaExcelenBD() {
            this.mostrarCargador=true
            axios.post(
                process.env.VUE_APP_RUTA_PRODUCTO,{
                excel:this.resultadoExcel,
                accion:'cargar'
        }).then(response => {
                console.log("Respuesta axios")
                console.log(response.data)
                this.mostrarCargador=false
                this.dialogConfirmar=false
                this.textoLista=unescape(encodeURIComponent(response.data))
                this.snackbarRevision=true
            })
        }
    }
}

</script>