<template>
    <v-app>
        <v-card>
            <v-card-title>
                Asociar usuarios a grupos
            </v-card-title>
            <v-card-text>
                <v-expansion-panels>
                    <v-expansion-panel
                    v-for="grupo in dataGrupos"
                    :key="grupo.id_grupo"
                    v-on:click="cargarUsuariosgrupo(grupo.id_grupo)"
                >
                    <v-expansion-panel-header>
                        {{ grupo.nombre }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                            <v-row>
                                <v-col v-for="usuario in dataUsuarios" :key="usuario.id_usuario">
                                    <v-checkbox
                                        v-on:click="actualizarUsuarios(grupo,usuario)"
                                        v-model="chkUsuario"
                                        :value="usuario.id_usuario"
                                        :label="usuario.nombre">

                                    </v-checkbox>
                                </v-col>
                            </v-row>
                            <v-btn v-show="btnActualizarUsuarios" class="mandarin-fondo jet-texto" v-on:click="actualizarUsuarios(grupo.id_grupo)">
                                Actualizar usuarios en grupo
                            </v-btn>
                    </v-expansion-panel-content>

                </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
        </v-card>
        <v-snackbar
            v-model="snackbar">
            {{ snackTexto }}
        </v-snackbar>
    </v-app>
</template>

<script>
import axios from 'axios'
export default {
    name:'AsociarGrupos', 

    data() { 
        return {
            dataGrupos: [],
            dataUsuarios:[],
            chkUsuario:[],
            btnActualizarUsuarios:false,
            snackbar:false,
            snackTexto:''
        }
    },
    
    created() {
        this.listaGrupos()
    },

    methods: {
        listaGrupos:function() {
            axios.get(process.env.VUE_APP_API_GRUPOS_LISTAR).then(response => {
                        this.dataGrupos=response.data
                })
        }, 

        cargarUsuariosgrupo:function(id_grupo) {
            this.chkUsuario=[]
            var ruta = process.env.VUE_APP_API_GRUPOS_CONSULTAR + "/" + id_grupo
            console.log("Ruta consultada -> " + ruta)
            axios.get(ruta).then(response => {
                response.data.forEach(usuario => {
                    this.chkUsuario.push(usuario.id_usuariogrupo == null ? 0 : usuario.id_usuariogrupo)
                })             
                this.dataUsuarios=response.data
            })
        },

        actualizarUsuarios:function(grupo)
        {
            var ruta = process.env.VUE_APP_API_GRUPOS_LIMPIAR + "/" + grupo.id_grupo
            axios.get(ruta).then( response => {
                this.chkUsuario.forEach( idUsuario => {
                    if (idUsuario>0)
                    {
                    var ruta = process.env.VUE_APP_API_GRUPOS_ASOCIAR_USUARIO + "/" + idUsuario + "/" + grupo.id_grupo
                    console.log("Ruta actualizacion -> " + ruta)
                    axios.get(ruta)
                    }
                } )
            })
          
            this.snackTexto="Grupo " +  grupo.nombre + " actualizado"
            this.snackbar=true
        }, 

       

        validaCheck(usuario)
        {
            return (usuario.id_usuario == usuario.id_usuariogrupo) ? usuario.id_usuario: false
        }
        

    }
}
</script>